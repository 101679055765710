<template>
  <div id="MRangchaProductList">
    <MHeader />
    <div class="banner">
      <img
        src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/rangcha_banner_new.jpg"
        alt=""
        srcset=""
      />
    </div>
    <div>
      <van-tabs v-model="activeName" color="#45B035" animated :ellipsis="false">
        <van-tab title="无糖果茶系列" name="a">
          <div style="height: 120vw;">
            <div class="product_box bg_a" style="margin: 9.733vw auto 37.867vw">
              <div class="title">无糖果茶系列</div>
              <div class="dec">
                <div class="dec_item">海拔800米以上高山茶园</div>
                <div class="dec_item">维生素C100mg/500ml</div>
                <div class="dec_item">满足成人每日所需维生素C</div>
                <div class="dec_item">100%真茶萃取</div>
                <div class="dec_item">0糖0能量 好喝不怕胖</div>
              </div>
              
              <div class="box">
                <div class="box_item" @click="toRangchaDetail(9)">
                  <div class="img_box">
                    <img
                      src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-1-y.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="item_title">
                    <div class="title_left">葡萄乌龙茶</div>
                    <div class="title_right">
                      <!-- <img
                        src="../../assets/product/tuxing.png"
                        alt=""
                        srcset=""
                      /> -->
                    </div>
                  </div>
                </div>
                <div class="box_item" @click="toRangchaDetail(16)">
                  <div class="img_box">
                    <img
                      src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-2-y.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="item_title">
                    <div class="title_left">柠C茉莉茶</div>
                    <div class="title_right">
                      <!-- <img
                        src="../../assets/product/tuxing.png"
                        alt=""
                        srcset=""
                      /> -->
                    </div>
                  </div>
                </div>
                <div class="box_item" @click="toRangchaDetail(2)">
                  <div class="img_box">
                    <img
                      src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-3-y.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="item_title">
                    <div class="title_left">柚C茉莉茶</div>
                    <div class="title_right">
                      <!-- <img
                        src="../../assets/product/tuxing.png"
                        alt=""
                        srcset=""
                      /> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="box_second">
                <div class="box_item" @click="toRangchaDetail(15)">
                  <div class="img_box">
                    <img
                      src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-4-y.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="item_title">
                    <div class="title_left">青梅龙井茶</div>
                    <div class="title_right">
                      <!-- <img
                        src="../../assets/product/tuxing.png"
                        alt=""
                        srcset=""
                      /> -->
                    </div>
                  </div>
                </div> 
                <div class="box_item" @click="toRangchaDetail(17)">
                  <div class="img_box">
                    <img
                      src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-5-y.png"
                      alt=""
                      srcset=""
                    />
                  </div>
                  <div class="item_title">
                    <div class="title_left">橙C乌龙茶</div>
                    <div class="title_right">
                      <!-- <img
                        src="../../assets/product/tuxing.png"
                        alt=""
                        srcset=""
                      /> -->
                    </div>
                  </div>
                </div> 
              </div>
              
            </div>
          </div>
        </van-tab>
        <van-tab title="饭后茶系列" name="c">
          <div class="product_box bg_c" style="margin: 9.733vw auto 37.867vw">
            <div class="title">饭后茶系列</div>
            <div class="dec">
              <div class="dec_item">搭配植物食材，</div>
              <div class="dec_item">饭后一瓶自然轻爽</div>
              <div class="dec_item">海拔800米以上高山茶园</div>
              <div class="dec_item">原叶茶萃取</div>
              <div class="dec_item">0糖0能量</div>
            </div>
            <div class="box">
              <div class="box_item" @click="toRangchaDetail(18)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-6-y.png.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">饭后普洱</div>
                  <div class="title_right"></div>
                </div>
              </div>
              <div class="box_item" @click="toRangchaDetail(19)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-7-y.png.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">饭后乌龙</div>
                  <div class="title_right"></div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab title="高山茶系列" name="d">
          <div class="product_box bg_d" style="margin: 9.733vw auto 37.867vw">
            <div class="title">高山茶系列</div>
            <div class="dec">
              <div class="dec_item">海拔800米以上高山茶园</div>
              <div class="dec_item">0糖0能量</div>
              <div class="dec_item">不添加香精不添加防腐剂</div>
              <div class="dec_item">原叶茶萃取</div>
              <div class="dec_item">清爽甘甜少苦涩</div>
            </div>
            <div class="box">
              <div class="box_item" @click="toRangchaDetail(12)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-9-y.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">茉莉乌龙茶</div>
                  <div class="title_right"></div>
                </div>
              </div>
              <div class="box_item" @click="toRangchaDetail(13)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-10-y.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">竹香乌龙茶</div>
                  <div class="title_right"></div>
                </div>
              </div>
              <div class="box_item" @click="toRangchaDetail(14)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-8-y.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">桂花乌龙茶</div>
                  <div class="title_right"></div>
                </div>
              </div>
              
            </div>
          </div>
        </van-tab>
        <van-tab title="轻养水系列" name="e">
          <div class="product_box bg_e" style="margin: 9.733vw auto 37.867vw">
            <div class="title">轻养水系列</div>
            <div class="dec">
              <div class="dec_item">传统熬煮工艺</div>
              <div class="dec_item">配料干净</div>
              <div class="dec_item">0糖0脂0能量</div>
              <div class="dec_item">冷热都好喝</div>
            </div>
            <div class="box">
              <div class="box_item" @click="toRangchaDetail(20)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-11-y.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">红豆薏米水</div>
                  <div class="title_right"></div>
                </div>
              </div>
              <div class="box_item" @click="toRangchaDetail(21)">
                <div class="img_box">
                  <img
                    src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/2-12-y.png"
                    alt=""
                    srcset=""
                  />
                </div>
                <div class="item_title">
                  <div class="title_left">红枣枸杞水</div>
                  <div class="title_right"></div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <MobileConncatUs />
    <MFooter />
  </div>
</template>

<script>
import MHeader from "@/components/MobileHeader";
import MFooter from "@/components/MobileFooter";
import MobileConncatUs from "@/components/MobileConncatUs";
export default {
  name: "RangchaProductList",
  components: {
    MHeader,
    MFooter,
    MobileConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      activeName: "a",
    };
  },
  watch: {
    "$route.query.type": {
      handler(newVal) {
        if (newVal) {
          this.activeName = newVal;
        }
      },
      immediate: true
    }
  },
  methods: {
    toRangchaDetail(index) {
      this.$router.push(`/mrangcha/detail?pid=${index}`);
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped lang="less">
#MRangchaProductList {
  min-height: calc(100vh - 44px - 26.4vw);
  background: #e9e9df;
  margin-top: 18.667vw;
  /* margin-bottom: 37.333vw; */
  .banner {
    width: 100vw;
    height: 46.667vw;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product_box {
    width: 93.6vw;
    height: 42.667vw;
    position: relative; 
    background-repeat: no-repeat;
    background-size: cover;
    .title {
      width: 8vw;
      height: 32.8vw;
      position: absolute;
      top: -4.933vw;
      left: 3.2vw;
      background: #45B035;
      color: white;
      font-size: 14px;
      font-weight: 500;
      writing-mode: vertical-lr; //垂直方向，从左向右
      text-align: center;
      letter-spacing: 1px;
      line-height: 8vw;
    }
    .dec {
      width: 51.667vw;
      height: 32.333vw;
      position: absolute;
      top: 2.467vw;
      left: 16.133vw;
      /* font-size: 1.467vw; */
      font-size: 8px;
      color: white;
      font-weight: 400;
      .dec_item {
        height: 100%;
        writing-mode: vertical-lr; //垂直方向，从左向右
        letter-spacing: 4px;
        float: left;
        margin-right: 1.333vw;
      }
    }
    .box {
      height: 36.8vw;
      position: absolute;
      top: 32vw;
      left: 7.867vw;
      overflow: hidden;
      .box_item {
        width: 24.267vw;
        height: 36.8vw;
        float: left;
        padding: 0.533vw 0.533vw 0 0.533vw;
        box-sizing: border-box;
        background: white;
        margin-right: 2.4vw;
        .img_box {
          width: 100%;
          height: 30.933vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_title {
          width: 100%;
          height: 5.333vw;
          background: #fff;
          .title_left {
            float: left;
            font-size: 10px;
            font-weight: 400;
            color: #121212;
            line-height: 5.333vw;
            text-align: center;
            width: 100%;
          }
          .title_right {
            float: right;
            /*  width: 3.2vw; */
            width: 0;
            height: 5.333vw;
            line-height: 1.333vw;
            img {
              width: 3.2vw;
              height: 1.067vw;
            }
          }
        }
      }
    }
  }
  // 用于产品第二行
  .box_second {
      height: 36.8vw;
      position: absolute;
      top: 72vw;
      left: 7.867vw;
      overflow: hidden;
      .box_item {
        width: 24.267vw;
        height: 36.8vw;
        float: left;
        padding: 0.533vw 0.533vw 0 0.533vw;
        box-sizing: border-box;
        background: white;
        margin-right: 2.4vw;
        .img_box {
          width: 100%;
          height: 30.933vw;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item_title {
          width: 100%;
          height: 5.333vw;
          background: #fff;
          .title_left {
            float: left;
            font-size: 10px;
            font-weight: 400;
            color: #121212;
            line-height: 5.333vw;
            text-align: center;
            width: 100%;
          }
          .title_right {
            float: right;
            /*  width: 3.2vw; */
            width: 0;
            height: 5.333vw;
            line-height: 1.333vw;
            img {
              width: 3.2vw;
              height: 1.067vw;
            }
          }
        }
      }
    }
  
  .bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/guoweicha.jpg");
  }
  .bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/index/rucha.jpg");
  }
  .bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/fanhou.jpg");
  }
  .bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/gaoshan.png");
  }

  .bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/mobile/product/yangsheng.jpg");
  }
}
</style>
